<div class="social-container">
    
    <div class="icon-container"  >
        <h1 id="luna" style="font-size: 2em;font-family: 'Pacifico', cursive;width: 100%;">Let's Connect</h1>
    </div>

    <social-networks></social-networks>
    
    <div class="mail-list-container">
        <p>
            Sign Up For A Free Screensaver
        </p>
        <div class="input-container">
            <input type="text" placeholder="Name" [(ngModel)]="subscriber.name">
        </div>
        <div class="input-container">
            <input type="text" placeholder="Your email address" [(ngModel)]="subscriber.email">
        </div>
        <button (click)="addToEmailList()">
            SIGN UP &nbsp;
            <fa-icon [icon]="heartIcon" style="color: #DE76B4"></fa-icon>
        </button>

        <span style="text-align: center;margin-top: 1em;">
            Your free screensaver will be sent directly to you. 
            By filling out this form you agree to recieve texts/emails 
            from my team.
        </span>
        <span style="text-align: center; margin-top:1em">
            Copyright &copy; {{date | date: 'yyyy'}} LunaBae All Rights Reserved.
        </span>
    </div>

</div>