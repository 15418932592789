import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { GalleryComponent } from './gallery/gallery.component';
import { HomeComponent } from './home/home.component';
import { NFTComponent } from './nft/nft.component';
import { WallpaperComponent } from './wallpaper/wallpaper.component';

const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: '/'},
  {path: '', component: HomeComponent, data: {animation: 'HomeAnimation'}},
  {path: 'home', component: HomeComponent, data: {animation: 'LandingAnimation'}},
  {path: 'about', component: AboutComponent},
  {path: 'gallery', component: GalleryComponent, data: {animation: 'GalleryAnimation'}},
  {path: 'wallpaper', component: WallpaperComponent, data: {animation: 'WallpaperAnimation'}},
  {path: 'nfts', component: NFTComponent, data: {animation: 'NFTSAnimation'}}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
