import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  faInstagram,
  faSnapchat,
  faYoutube,
  faFacebook,
  faTiktok
} from '@fortawesome/free-brands-svg-icons';
import { 
  faHeart
} from '@fortawesome/free-solid-svg-icons'
import { Subscriber } from '../models/subscriber.model';

// services
import { MailingService } from '../mailing.service';

@Component({
  selector: 'app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.less']
})
export class SocialComponent implements OnInit, AfterViewInit {

  snapchatIcon = faSnapchat;
  instagramIcon = faInstagram;
  youtubeIcon = faYoutube;
  facebookIcon = faFacebook;
  heartIcon = faHeart;
  tiktokIcon= faTiktok;

  subscriber: Subscriber = new Subscriber();

  date = new Date(Date.now());
  
  constructor(
    private mailingSrv: MailingService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    let isAppleMobile = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    let isAndroid = /Android/i.test(navigator.userAgent);
    let link = document.querySelector('#facebook-link') as HTMLLinkElement;
    link.href = isAppleMobile ? 
      'fb://profile/LunaBaeOfficial' :
      isAndroid ? 'fb://page/LunaBaeOfficial' : 'https://facebook.com/LunaBaeOfficial' 
  }

  async addToEmailList(): Promise<void> {
    const subscrived = await this.mailingSrv.SubscribeToAmor(this.subscriber);

     if(subscrived) {
      this.closeSosial();
      this.router.navigate(['wallpaper'])
    }

  }

  main(): void {

    this.closeSosial();

    this.router.navigate(['/']);
  }


  /**
   * Closes the window
   */
  closeSosial(): void {
    const div = document.querySelector('.nav-container') as HTMLDivElement;

    console.log(div);

    div.classList.remove('nav-container-open');
  }

}
