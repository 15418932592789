import { Injectable } from '@angular/core';


import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class WallpaperService {

    /**
     * secret key to download wallpaper file
     */
    keySource = new BehaviorSubject<string>(undefined);

    constructor(
    ) {

    }

    /**
     * ## Sets {@link keySource} value
     * Gets secret key value and sets it as the new value
     * @param newKey secret key
     */
    setNext(newKey: string): void {
        this.keySource.next(newKey);
    }


    /**
     * ## Gets secret key value
     * Returns the latest available key from to use for
     * access to wallpaper file
     * @returns Secret key value
     */
    getKeySource(): string {
        return this.keySource.value;
    }


}