<div class="about-container">
    <div class="controls">
        <fa-icon [icon]="mailIcon" (click)="inquiries()" size="2x" class="icon"></fa-icon>
        <fa-icon [icon]="imgsIcon" [routerLink]="['/gallery']" size="2x" class="icon"></fa-icon>
    </div>

    <div class="sec">
        <div class="img" style="background-image: url(../../assets/tennis.jpg);">
        </div>
        <img src="../../assets/tenniss.png" width="100%" height="100%" alt="" class="mobile">
        <h3>
            LunaBae is an AfroLatina, Actress, Influencer, and Model known for sharing innovative beauty,
            fashion, and travel content across her various platforms.
        </h3>
    </div>

    <div class="sec">
        <div class="img" style="background-image: url(../../assets/Haydee63.jpg);">
        </div>
        <img src="../../assets/Haydee63s.png" width="100%" height="100%" alt="" class="mobile">
        <h3>
            Born and raised in Bayamo, Cuba, she moved to the US at an early age to continue chasing her dreams. Early in 2019, she was discovered by a Louisville photographer and earned an opportunity to walk in NYFW later that year. 
        </h3>
    </div>

    <div class="sec">
        <div class="img" style="background-image: url(../../assets/lunabae_classy.jpg);">
        </div>
        <img src="../../assets/lunabae_classys.png" width="100%" height="100%" alt="" class="mobile">
        <h3>
            She is the first Cuban-Jamaican model to be featured in Louisville's Courier Journal and Today's Woman Magazine publications as well as moments on television on WHAS News. 
        </h3>
    </div>

    <div class="sec">
        <div class="img" style="background-image: url(../../assets/lunabae_colorful.jpg);"></div>
        <img src="../../assets/lunabae_colorfuls.png" width="100%" height="100%" alt="" class="mobile">
        <h3>
            She has been featured in multiple music videos across major networks like VH1 
            reaching 2.1M views on YouTube. She has also been cast in various films including "Dance For Me" on BET+ release on 2023. 
        </h3>
    </div>

      <div class="sec">
        <div class="img" style="background-image: url(../../assets/lunabae_hands.jpeg);">
        </div>
        <img src="../../assets/lunabae_handss.png" width="100%" height="100%" alt="" class="mobile">
        <h3>
            Continue to follow this talent on her journey to breaking barriers in the entertainment industry.
        </h3>
        <social-networks color="white" style="position: absolute; left:0; width: 100vw; bottom: 0;"></social-networks>
    </div>

</div>
<!-- <app-social></app-social> -->
