import { Component, OnInit } from '@angular/core';

//// services
import { WallpaperService } from '../services/wallpaper.service';
import { MailingService } from '../mailing.service';

@Component({
    selector: 'app-wallpaper',
    templateUrl: './wallpaper.component.html',
    styleUrls: ['./wallpaper.component.less']
})
export class WallpaperComponent implements OnInit {


    constructor(
        private wpService: WallpaperService,
        private mailService: MailingService,
    ) { }


    ngOnInit(): void {
    }


    /**
     * ## Downlods wallpaper
     * Passes subscription key to download wallpaper
     */
    download(): void {
        this.mailService.downloadWallpaper();
    }
    
}