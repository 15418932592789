<div class="wallpaper-container">
    <div>
        <p>
           Thank You for staying connected with me. 🥰 <span style="color:red"> ❤ </span> Please make sure to check your email 
           to recieve your screensaver! 💋
        </p>
        <!-- <p>
            Thank You for staying connected with me. 🥰 <span style="color:red"> ❤ </span> Please make sure to download 
            your screensaver! 💋
         </p> -->
    </div>
    <!-- <div>
        <p>
            P.S make sure to check you 
            <mat-icon>email</mat-icon> 
            <sup>
                <mat-icon>report_gmailerrorred</mat-icon>
            </sup> 
        </p>
    </div> -->
    <!-- <div>
        <button mat-mini-fab style="background-color: white; color: #DE76B4;" (click)="download()">
            <mat-icon>file_download</mat-icon>
        </button>
    </div> -->
</div>