import { Component, OnInit, AfterViewInit, Input } from '@angular/core';


import {
  faInstagram,
  faSnapchat,
  faYoutube,
  faFacebook,
  faTiktok,
  faTwitter,
  faXTwitter
} from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'social-networks',
  templateUrl: './socialnetworks.component.html',
  styleUrls: ['./socialnetworks.component.less']
})
export class SocialnetworksComponent implements OnInit, AfterViewInit {
  @Input() color: string = '#E09FC9'

  snapchatIcon = faSnapchat;
  instagramIcon = faInstagram;
  youtubeIcon = faYoutube;
  facebookIcon = faFacebook;
  tiktokIcon= faTiktok;
  twitterIcon = faTwitter;
  xTwitterIcon = faXTwitter;


  constructor() { }

  ngOnInit(): void {
   
  }

  ngAfterViewInit(): void {
    let isAppleMobile = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    let isAndroid = /Android/i.test(navigator.userAgent);
    let link = document.querySelector('#facebook-link') as HTMLLinkElement;
    link.href = isAppleMobile ? 
      'fb://profile/LunaBaeOfficial' :
      isAndroid ? 'fb://page/LunaBaeOfficial' : 'https://facebook.com/LunaBaeOfficial' 
  }

}
