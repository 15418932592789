<div class="social-network-container">
        

    <a href="https://instagram.com/lunabaeofficial" target="_blank" [style.color]="color">
        <fa-icon [icon]="instagramIcon" size="2x"></fa-icon>
    </a>

    <a href="https://snapchat.com/add/haydee_luna22" target="_blank" [style.color]="color">
        <fa-icon [icon]="snapchatIcon" size="2x"></fa-icon>
    </a>

    <a id="facebook-link" target="blank" [style.color]="color">
        <fa-icon [icon]="facebookIcon" size="2x"></fa-icon>
    </a>

    <a href="https://www.tiktok.com/@lunabaeofficial" target="_blank" [style.color]="color">
        <fa-icon [icon]="tiktokIcon" size="2x"></fa-icon>
    </a>

    <a href="https://twitter.com/love_lunabae" target="_blank" [style.color]="color">
        <fa-icon [icon]="xTwitterIcon" size="2x"></fa-icon>
    </a>

</div>