import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  faInstagram,
  faSnapchat,
  faYoutube,
  faFacebook
} from '@fortawesome/free-brands-svg-icons'
import {
  faImages
} from '@fortawesome/free-regular-svg-icons';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit, AfterViewInit {

  snapchatIcon = faSnapchat;
  instagramIcon = faInstagram;
  youtubeIcon = faYoutube;
  galleryIcon = faImages;
  facebookIcon = faFacebook;
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {


  }

  ngAfterViewInit(): void {
    let isAppleMobile = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    let isAndroid = /Android/i.test(navigator.userAgent);
    let link = document.querySelector('#facebook-link') as HTMLLinkElement;
    link.href = isAppleMobile ?
      'fb://profile/LunaBaeOfficial' :
      isAndroid ? 'fb://page/LunaBaeOfficial' : 'https://facebook.com/LunaBaeOfficial'
  }

  Link(link: string): void {

    if(link === 'facebook-link') {

      let isAppleMobile = /iPhone|iPad|iPod/i.test(navigator.userAgent);
      let isAndroid = /Android/i.test(navigator.userAgent);
      let link = isAppleMobile ?
      'fb://profile/LunaBaeOfficial' :
      isAndroid ? 'fb://page/LunaBaeOfficial' : 'https://facebook.com/LunaBaeOfficial'

      window.open(link, '_blank');
    }

    console.log(link)
    this.router.navigate([`/${link}`])
  }

  collabAction() {
    window.location.href = "mailto:hello@lunabae.love"
  }

  externalLink(link: string): void {
    window.open(link, '_blank')
  }
}
