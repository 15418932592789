import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { saveAs } from 'file-saver';


// models
import { Subscriber } from './models/subscriber.model';


interface SubscriptionResponse {
  message: string,
  success?: boolean
};

@Injectable({
  providedIn: 'root'
})
export class MailingService {

  constructor(
    private http: HttpClient,
    private snack: MatSnackBar,
  ) { }


  async SubscribeToAmor(sub: Subscriber): Promise<boolean> {

    console.log(sub);

    const webCall = () => this.http.post<SubscriptionResponse>(`/api/subscribe`, sub).toPromise();

    try {
      const response = await webCall();


      if(response.success) {
        return true;
      }


      this.snack.open(response.message, 'X', {duration: 10 * 1000});
      return false;
    }
    catch(error) {
      this.snack.open(error.message, 'X');
      console.log(error);
      return false;
    }

  }

  async downloadWallpaper() {
    const webCall = () => this.http.post(`/api/downloadwp`, {}, {
      responseType: 'arraybuffer'
    }).toPromise();

    try {
      const response = await webCall();

      console.log(Response);

      let blob = new Blob([response], {type: 'mime/jpeg'});
      saveAs(blob, "lunabae_wallpaper.jpeg");

    }
    catch(error) {
      this.snack.open(error.message, 'X');
      console.log(error);
    }
  }


  async getPortfolioAssets(): Promise<string[]> {
    try {
      const webCall = () => this.http.get<string[]>(`/api/passets`).toPromise();

      const response = await webCall();

      return response;
    }
    catch(error) {
      this.snack.open(error.message, 'X');
      console.log(error);
    }
  }


}
