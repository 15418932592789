<div class="nav-icons">
    <button mat-mini-fab id="home" [routerLink]="['/home']">
        <mat-icon>holiday_village</mat-icon>
    </button>
    <button mat-mini-fab id="burger-menu" (click)="open()">
        <mat-icon>groups</mat-icon>
    </button>
</div>


<div class="nav-container">
    
    <div class="nav-content">
        <app-social></app-social>
    </div>

    <div class="nav-wall-splash" (click)="close()">

    </div>

</div>
