import { Component, OnInit } from '@angular/core';

import { 
  faEnvelope,
  faImages,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.less']
})
export class AboutComponent implements OnInit {

  mailIcon = faEnvelope;
  imgsIcon = faImages;

  constructor() { }

  ngOnInit(): void {
  }

  inquiries(): void {
    window.location.href = "mailto:hello@lunabae.love?subject=Business%20Inquiry";
  }

}
