import { Component, OnInit } from '@angular/core';

import { MailingService } from '../mailing.service';

interface iImage {
  name: string;
  high_quality?: string;
  low_quality?: string;
}

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.less']
})
export class GalleryComponent implements OnInit {


  assets: iImage[][] = [];

  _assets: iImage[] = [];

  constructor(
    private mSrv: MailingService
  ) { }

  ngOnInit(): void {
    this.mSrv.getPortfolioAssets().then(asts => {
      asts.push('/assets/LunaBae_Highlands-7.jpg');

      asts.filter(v => v.indexOf('_small') !== -1).forEach(v => {
        console.log(v.substring(v.lastIndexOf('/')).replace(v.substring(v.indexOf('_small'), v.lastIndexOf('.')), ''));
        this._assets.push({
          low_quality: v,
          name: v.substring(v.lastIndexOf('/')).replace(v.substring(v.indexOf('_small'), v.lastIndexOf('.')), '')
        })
      })

      asts = asts.filter(v => v.indexOf('_small') === -1);

      asts.forEach((v, i) =>  {


        const item = this._assets.find(i => i.name === v.substring(v.lastIndexOf('/')));

        if(item)
          item.high_quality = v;
        else {
          this._assets.push({
            name: v.substring(v.lastIndexOf('/')),
            high_quality: v
          })
        }
        
        if(v == 'https://res.cloudinary.com/journeysrv/image/upload/v1675724678/lunabae/portfolio/LunaBae_Newburg-17_ov4jnr.jpg') {
          asts.splice(i, 1);
          asts.splice(0, 0, v);
        }

        if(v == 'https://res.cloudinary.com/journeysrv/image/upload/v1675721466/lunabae/portfolio/Photo_Jun_26_2022_5_00_44_PM_jwrwd1.jpg') {
          asts.splice(i, 1);
          asts.splice(0,0, v);
        }

        if(v === 'https://res.cloudinary.com/journeysrv/image/upload/v1675724634/lunabae/portfolio/lunabae_actitude_vwiozr.jpg') {
          asts.splice(i,1);
          asts.splice(0,0,v);
        }
      })

      this._assets.forEach((item, i) => {
        

        if(item.name === '/LunaBae_Newburg-17_ov4jnr.jpg') {
          console.log(item);
          this._assets.splice(i, 1);
          this._assets.splice(0, 0, item);
        }

        if(item.name === '/Photo_Jun_26_2022_5_00_44_PM_jwrwd1.jpg') {
          this._assets.splice(i, 1);
          this._assets.splice(0,0, item);
        }


        if(item.name === '/lunabae_actitude_vwiozr.jpg') {
          this._assets.splice(i,1);
          this._assets.splice(0,0,item);
        }

        
      })

      const number = parseInt((this._assets.length / 4).toString());
      
      console.log(this._assets[0]);
      while(this._assets.length > 0) {
        this.assets.push(this._assets.splice(0,number))
      }
    })
  }

}
