import { Component, OnInit } from '@angular/core';




@Component({
    selector: 'app-nft',
    templateUrl: './nft.component.html',
    styleUrls: ['./nft.component.less']
})
export class NFTComponent implements OnInit {

    constructor() {

    }

    ngOnInit(): void {
        
    }

    intoWeb3(link: string): void {
        window.location.href = link;
    }
}

