import { Component, AfterViewInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { slideInAnimation } from './animations';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  animations: [slideInAnimation]
})
export class AppComponent implements AfterViewInit {
  title = 'lunabae-client';

  prepareRouter(outlet: RouterOutlet): void {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  ngAfterViewInit(): void { 
  //   if(window.sessionStorage.getItem('navOpen') !== 'true') { 
  //     setTimeout(() => {
  //       const div = document.querySelector('.nav-container') as HTMLDivElement;
    
  //       console.log(div);
    
  //       div.classList.add('nav-container-open');
  
  //       window.sessionStorage.setItem('navOpen', 'true');
  //     }, 1 * 500)
  //   }
  }
}
