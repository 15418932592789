import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialModule } from './app-material.module';

import { AppRoutingModule } from './app-routing.module';


import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { GalleryComponent } from './gallery/gallery.component';
import { AboutmeComponent } from './aboutme/aboutme.component';
import { AboutComponent } from './about/about.component';
import { SocialComponent } from './social/social.component';
import { NavComponent } from './nav/nav.component';
import { WallpaperComponent } from './wallpaper/wallpaper.component';
import { SocialnetworksComponent } from './socialnetworks/socialnetworks.component';

// services
import { MailingService } from './mailing.service';
import { NavService } from './services/nav.service';
import { WallpaperService } from './services/wallpaper.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    GalleryComponent,
    AboutmeComponent,
    AboutComponent,
    SocialComponent,
    WallpaperComponent,
    NavComponent,
    SocialnetworksComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    FontAwesomeModule
  ],
  providers: [
    MailingService,
    NavService,
    WallpaperService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
