import { Component, OnInit } from '@angular/core';

import { 
  faBars
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.less']
})
export class NavComponent implements OnInit {

  barsIcon = faBars;
  constructor() { }

  ngOnInit(): void {
  }

  open() {
    const div = document.querySelector('.nav-container') as HTMLDivElement;

    console.log(div);

    div.classList.add('nav-container-open');

    // setTimeout(_=> {
    //   div.style.backgroundColor = "rgba(0,0,0,.5)";
    // }, 500)
  }

  close() {
    const div = document.querySelector('.nav-container') as HTMLDivElement;
    // div.style.removeProperty('background-color');
    
    div.classList.remove('nav-container-open');
    // setTimeout(_=> {
    // }, 500)

  }

}
