<!-- <div>
    <h1>NFTs ACTION !!</h1>
</div> -->
<div class="nfts-container">
    

    <div class="nft-card" (click)="intoWeb3('https://opensea.io/assets/solana/8t7Yw2TKwRF1yAb51rCQMtrTJJ56XW4VPWHteDe1fuoK')">
        <div>
            
        </div>
        <p>
            <span>
                LunaBae Ninja#1 
            </span>
            <span>
                0.002 ETH
            </span>
        </p>
    </div>

    <div class="nft-card">
        <div>
            
        </div>
        <p>
            <span>
                LunaBae Ninja#1 
            </span>
            <span>
                0.002 ETH
            </span>
        </p>
    </div>

    <div class="nft-card">
        <div>
            
        </div>
        <p>
            <span>
                LunaBae Ninja#1 
            </span>
            <span>
                0.002 ETH
            </span>
        </p>
    </div>
</div>